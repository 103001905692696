<template>
  <div class="all-building-types">
    <AdminTable title="All Building Types" :list="buildingTypeList" :fields="fields" :loading="loading"
    @update-list="update" @edit="editBuildingType"/>
  </div>
</template>

<script>
import AdminTable from '@/components/admin/AdminTable'
import { buildingTypeService } from '@/services/admin'

export default {
  name: 'AdminAllBuildingTypes',
  components: {
    AdminTable,
  },
  data() {
    return {
      buildingTypeList: [],
      loading: false,
    }
  },
  computed: {
    fields() {
      return [
        { key: 'id', label: 'ID' },
        { key: 'name', label: 'Name' },
        { key: 'actions', label: 'Actions', type: 'actions', actions: ['edit'] },
      ]
    }
  },
  async beforeMount() {
    await this.update()
  },
  methods: {
    async update() {
      this.loading = true
      this.buildingTypeList = await buildingTypeService.getBuildingTypes()
      this.$store.commit('adminBuildingType/setBuildingTypes', this.buildingTypeList)
      this.loading = false
    },
    editBuildingType(buildingType) {
      this.$store.commit('adminBuildingTypeStore/setBuildingType', buildingType)
      this.$router.push({ name: 'building-type-edit', params: { id: buildingType.id } })
    }
  }
}
</script>

<style>

</style>